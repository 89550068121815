import React from "react";
import styled from "styled-components";

const SVGRoot = styled.svg`
    fill: currentColor;
    path: currentColor;
    display: block;
`;

type Props = {
    className?: string;
};

const PoweredByCC = ({ className }: Props) => (
    <SVGRoot className={className} viewBox="0 0 460.2 80">
        <path
            d="M132.2,23.2c-5.9,0-10.6,2-14,5.9s-5.1,9.3-5.1,16.2c0,7.1,1.7,12.6,5,16.4c3.3,3.8,8,5.8,14.1,5.8c3.7,0,8-0.7,12.9-2.1
    V71c-3.7,1.4-8.3,2.1-13.8,2.1c-7.9,0-14-2.5-18.4-7.3c-4.3-4.8-6.5-11.7-6.5-20.5c0-5.6,1.1-10.4,3.1-14.6c2.1-4.2,5.1-7.4,9-9.7
    c3.9-2.3,8.5-3.4,13.8-3.4c5.7,0,10.6,1.1,14.8,3.1l-2.6,5.4C140.3,24.2,136.3,23.2,132.2,23.2z"
        />
        <path d="M161.9,72.4h-6.1V15h6.1V72.4z" />
        <path
            d="M192,73.1c-6,0-10.7-1.9-14.1-5.5c-3.4-3.6-5.2-8.7-5.2-15.2s1.6-11.7,4.8-15.5c3.2-3.8,7.6-5.8,13-5.8c5.1,0,9,1.7,12.1,5
    s4.4,7.8,4.4,13.2v3.8h-28c0.1,4.7,1.3,8.3,3.6,10.8c2.3,2.5,5.5,3.7,9.6,3.7c4.3,0,8.6-0.9,13-2.7v5.5c-2.2,1-4.2,1.6-6.2,2
    C197,72.9,194.8,73.1,192,73.1z M190.4,36.3c-3.2,0-5.8,1.1-7.8,3.1c-2,2.2-3,5-3.4,8.7h21.1c0-3.8-0.9-6.8-2.6-8.8
    C196,37.4,193.5,36.3,190.4,36.3z"
        />
        <path
            d="M242.2,72.4l-1.2-5.8h-0.3c-2.1,2.6-4,4.2-6,5.1c-2,0.9-4.5,1.4-7.6,1.4c-4,0-7.2-1.1-9.4-3.1c-2.3-2.1-3.4-5-3.4-8.8
    c0-8.1,6.6-12.5,19.6-12.9l6.9-0.2v-2.6c0-3.1-0.7-5.5-2.1-7.1c-1.4-1.5-3.5-2.3-6.6-2.3c-3.3,0-7.2,1.1-11.5,3.1l-1.9-4.7
    c2-1.1,4.2-2,6.6-2.6c2.4-0.6,4.7-0.9,7.2-0.9c4.8,0,8.4,1.1,10.7,3.2c2.4,2.2,3.5,5.6,3.5,10.3v27.6L242.2,72.4L242.2,72.4z
     M228.3,68.1c3.8,0,6.8-1.1,9-3.1c2.2-2.1,3.2-5,3.2-8.7v-3.6l-6.1,0.3c-4.9,0.2-8.3,0.9-10.5,2.3c-2.2,1.4-3.2,3.4-3.2,6.3
    c0,2.3,0.7,3.9,2,5.1C224,67.5,226,68.1,228.3,68.1z"
        />
        <path
            d="M277.8,31.2c1.8,0,3.4,0.2,4.8,0.5l-0.9,5.7c-1.7-0.4-3.1-0.6-4.4-0.6c-3.2,0-6.1,1.4-8.4,4c-2.4,2.6-3.5,6-3.5,9.9v21.7
    h-6.1V31.9h5.1l0.7,7.5h0.3c1.5-2.6,3.3-4.6,5.4-6.1C272.9,31.9,275.3,31.2,277.8,31.2z"
        />
        <path
            d="M314.2,23.2c-5.9,0-10.6,2-14,5.9s-5.1,9.3-5.1,16.2c0,7.1,1.7,12.6,5,16.4s8,5.8,14.1,5.8c3.7,0,8-0.7,12.9-2.1V71
    c-3.7,1.4-8.3,2.1-13.8,2.1c-7.9,0-14-2.5-18.4-7.3c-4.3-4.8-6.5-11.7-6.5-20.5c0-5.6,1.1-10.4,3.1-14.6s5.1-7.4,9-9.7
    c3.9-2.3,8.5-3.4,13.8-3.4c5.7,0,10.6,1.1,14.8,3.1l-2.6,5.4C322.4,24.2,318.3,23.2,314.2,23.2z"
        />
        <path
            d="M362.7,72.4l-1.2-5.8h-0.3c-2.1,2.6-4,4.2-6,5.1c-2,0.9-4.5,1.4-7.6,1.4c-4,0-7.2-1.1-9.4-3.1c-2.3-2.1-3.4-5-3.4-8.8
    c0-8.1,6.6-12.5,19.6-12.9l6.9-0.2v-2.6c0-3.1-0.7-5.5-2.1-7.1c-1.4-1.5-3.5-2.3-6.6-2.3c-3.3,0-7.2,1.1-11.5,3.1l-1.9-4.7
    c2-1.1,4.2-2,6.6-2.6c2.4-0.6,4.7-0.9,7.2-0.9c4.8,0,8.4,1.1,10.7,3.2c2.4,2.2,3.5,5.6,3.5,10.3v27.6L362.7,72.4L362.7,72.4z
     M348.9,68.1c3.8,0,6.8-1.1,9-3.1c2.2-2.1,3.2-5,3.2-8.7v-3.6l-6.1,0.3c-4.9,0.2-8.3,0.9-10.5,2.3c-2.2,1.4-3.2,3.4-3.2,6.3
    c0,2.3,0.7,3.9,2,5.1C344.6,67.5,346.5,68.1,348.9,68.1z"
        />
        <path d="M386.1,72.4H380V15h6.1V72.4z" />
        <path
            d="M415.2,73.1c-5.9,0-10.4-1.8-13.6-5.4c-3.2-3.6-4.8-8.7-4.8-15.3c0-6.8,1.7-12,4.9-15.7c3.2-3.7,7.9-5.5,13.9-5.5
    c2,0,3.9,0.2,5.8,0.6c2,0.4,3.4,0.9,4.6,1.5l-1.9,5.2c-1.4-0.6-2.8-1-4.4-1.4s-3-0.5-4.2-0.5c-8.2,0-12.4,5.2-12.4,15.7
    c0,5,1,8.7,3,11.5c2.1,2.7,5,4,8.9,4c3.3,0,6.9-0.7,10.4-2.2V71C422.7,72.4,419.3,73.1,415.2,73.1z"
        />
        <path
            d="M461,61.3c0,3.7-1.4,6.7-4.2,8.7c-2.8,2.1-6.8,3-11.8,3c-5.4,0-9.5-0.9-12.6-2.6v-5.7c2,1,4,1.8,6.3,2.4
    c2.3,0.6,4.4,0.9,6.5,0.9c3.2,0,5.7-0.5,7.4-1.6c1.8-1,2.6-2.6,2.6-4.7c0-1.6-0.7-2.9-2.1-4c-1.4-1.1-4-2.5-7.9-3.9
    c-3.7-1.4-6.5-2.6-8-3.6c-1.6-1.1-2.7-2.3-3.5-3.5c-0.8-1.4-1.2-2.9-1.2-4.8c0-3.3,1.4-5.9,4-7.9c2.6-1.9,6.4-2.8,11-2.8
    c4.3,0,8.6,0.9,12.8,2.6l-2.2,5c-4-1.7-7.8-2.6-11-2.6c-2.9,0-5.1,0.5-6.6,1.4c-1.5,0.9-2.3,2.2-2.3,3.7c0,1.1,0.3,2,0.8,2.7
    c0.6,0.8,1.5,1.5,2.6,2.2c1.3,0.7,3.6,1.7,7.1,2.9c4.8,1.8,8,3.5,9.7,5.3C460.1,56.3,461,58.7,461,61.3z"
        />
        <path
            d="M40.3,4.3C60,4.3,76,20.4,76,40S60,75.7,40.3,75.7S4.7,59.7,4.7,40S20.7,4.3,40.3,4.3 M40.3,0c-22.1,0-40,17.9-40,40
s17.9,40,40,40s40-17.9,40-40S62.4,0,40.3,0L40.3,0z"
        />
        <rect x="39.9" y="32.2" width="15.2" height="3.3" />
        <path
            d="M33.5,35.5c2.9,2.8,4.4,6.1,4.4,10.1c0,3.6-1.2,6.8-3.7,9.4c-2.5,2.7-5.3,4-8.3,4c-3.1,0-5.9-1.3-8.5-4s-3.8-5.8-3.8-9.4
s1.2-6.8,3.7-9.4c2.5-2.7,5.3-4,8.5-4h14.5v3.3C40.3,35.5,33.5,35.5,33.5,35.5z M19.8,38.5c-1.8,2-2.7,4.4-2.7,7.1
c0,2.8,0.9,5.1,2.7,7.1c1.8,1.9,3.7,3,6,3s4.3-1,6-3s2.7-4.4,2.7-7.1c0-2.8-0.9-5.1-2.7-7.1s-3.7-3-6-3
C23.6,35.5,21.5,36.5,19.8,38.5z"
        />
        <path
            d="M55.1,19.3h7v3.4h-6.9c-2.6,0-4,0.6-4,1.9c0,0.9,0.4,1.6,1.3,2.4l9.9,8.3c3.1,2.6,4.6,6,4.6,10.2c0,3.5-1.2,6.7-3.7,9.3
c-2.5,2.7-5.3,4-8.3,4c-3.1,0-5.9-1.3-8.4-4s-3.8-5.8-3.8-9.3c0-3.3,1.1-6.1,3.2-8.8c2.1-2.6,4.6-4.1,7.4-4.5l-4-3.4
c-1.5-1.2-2.1-2.7-2.1-4.2c0-1.6,0.6-2.9,1.8-4C50.4,19.8,52.3,19.3,55.1,19.3z M49,38.5c-1.8,1.9-2.6,4.3-2.6,7.1s0.9,5.1,2.6,7.1
c1.8,1.9,3.7,3,6,3s4.3-1,6-3c1.8-1.9,2.6-4.3,2.6-7.1s-0.9-5.1-2.6-7.1c-1.7-2-3.7-3-6-3C52.6,35.5,50.6,36.5,49,38.5z"
        />
        <path
            d="M183.3,16.1c0,1.2-0.4,2.1-1.2,2.7c-0.8,0.6-1.9,1-3.3,1h-1.2v4.3h-1.9V12.7h3.3c1.4,0,2.5,0.3,3.2,0.9
    C182.9,14.1,183.3,15,183.3,16.1z M177.6,18.3h1c1,0,1.7-0.2,2.1-0.5c0.4-0.3,0.7-0.9,0.7-1.6c0-0.7-0.2-1.1-0.6-1.5
    c-0.4-0.3-1-0.5-1.9-0.5h-1.3V18.3z"
        />
        <path
            d="M196.4,18.4c0,1.9-0.5,3.3-1.4,4.3s-2.2,1.5-3.9,1.5c-1.7,0-3-0.5-4-1.5c-0.9-1-1.4-2.5-1.4-4.4c0-1.9,0.5-3.3,1.4-4.3
    c0.9-1,2.3-1.5,4-1.5c1.7,0,3,0.5,3.9,1.5C195.9,15.1,196.4,16.5,196.4,18.4z M187.7,18.4c0,1.4,0.3,2.5,0.9,3.2
    c0.6,0.7,1.4,1.1,2.5,1.1c1.1,0,1.9-0.4,2.5-1.1c0.6-0.7,0.8-1.8,0.8-3.2c0-1.4-0.3-2.5-0.8-3.2c-0.6-0.7-1.4-1.1-2.5-1.1
    c-1.1,0-2,0.4-2.5,1.1C188,15.9,187.7,17,187.7,18.4z"
        />
        <path
            d="M210.1,24.1h-2l-1.9-6.8c-0.1-0.3-0.2-0.7-0.3-1.3c-0.1-0.6-0.2-0.9-0.2-1.2c-0.1,0.3-0.1,0.8-0.3,1.3s-0.2,0.9-0.3,1.2
    l-1.9,6.8h-2l-1.5-5.7l-1.5-5.7h1.9l1.6,6.7c0.3,1.1,0.4,2,0.5,2.8c0.1-0.4,0.1-0.9,0.3-1.5c0.1-0.5,0.2-1,0.3-1.3l1.9-6.7h1.9
    l1.9,6.7c0.2,0.6,0.4,1.5,0.6,2.8c0.1-0.7,0.3-1.7,0.6-2.8l1.6-6.6h1.9L210.1,24.1z"
        />
        <path d="M221.8,24.1h-6.5V12.7h6.5v1.6h-4.6v3.1h4.3v1.6h-4.3v3.6h4.6V24.1z" />
        <path
            d="M226.8,19.5v4.6H225V12.7h3.2c1.5,0,2.6,0.3,3.3,0.8c0.7,0.6,1.1,1.4,1.1,2.5c0,1.4-0.7,2.4-2.2,3l3.2,5.1h-2.1l-2.7-4.6
    H226.8z M226.8,18h1.3c0.9,0,1.5-0.2,1.9-0.5c0.4-0.3,0.6-0.8,0.6-1.4c0-0.6-0.2-1.1-0.6-1.4s-1.1-0.4-1.9-0.4h-1.2V18z"
        />
        <path d="M242.3,24.1h-6.5V12.7h6.5v1.6h-4.6v3.1h4.3v1.6h-4.3v3.6h4.6V24.1z" />
        <path
            d="M254.8,18.3c0,1.9-0.5,3.3-1.6,4.3c-1,1-2.6,1.5-4.5,1.5h-3.2V12.7h3.5c1.8,0,3.2,0.5,4.2,1.5
    C254.3,15.1,254.8,16.5,254.8,18.3z M252.8,18.3c0-2.7-1.3-4.1-3.8-4.1h-1.6v8.3h1.3C251.4,22.5,252.8,21.1,252.8,18.3z"
        />
        <path
            d="M262.8,12.7h3.4c1.6,0,2.7,0.2,3.4,0.7c0.7,0.5,1.1,1.2,1.1,2.2c0,0.7-0.2,1.2-0.5,1.7c-0.3,0.4-0.8,0.7-1.5,0.8v0.1
    c0.8,0.2,1.4,0.4,1.8,0.9s0.6,1,0.6,1.8c0,1-0.4,1.8-1.1,2.4c-0.7,0.6-1.7,0.9-3,0.9h-4.1V12.7z M264.6,17.4h1.8
    c0.8,0,1.4-0.1,1.7-0.4c0.4-0.2,0.5-0.7,0.5-1.3c0-0.5-0.2-0.9-0.6-1.2c-0.4-0.2-1-0.4-1.8-0.4h-1.6V17.4z M264.6,18.9v3.6h2
    c0.8,0,1.4-0.1,1.8-0.4c0.4-0.3,0.6-0.8,0.6-1.4c0-0.6-0.2-1-0.6-1.3c-0.4-0.3-1-0.4-1.9-0.4H264.6z"
        />
        <path d="M277.3,17.9l2.7-5.2h2l-3.8,7v4.4h-1.9v-4.4l-3.8-7.1h2L277.3,17.9z" />
    </SVGRoot>
);

export default PoweredByCC;
