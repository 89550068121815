import App from "framework/components/App";
import AuthenticatedRoute from "framework/AuthenticatedRoute";
import OrganisationsController from "mafi/MafiOrganisationsController";
import AppLoading from "mafi/components/AppLoading";

export const getServerSideProps = async function ({ req }) {
    // Block Direct Access
    if (req.url.startsWith("/mafi/")) {
        return {
            notFound: true,
        };
    }

    return { props: {} };
};

export default function Page() {
    return (
        <App loadingView={<AppLoading />}>
            <AuthenticatedRoute>
                <OrganisationsController />
            </AuthenticatedRoute>
        </App>
    );
}
