import styled from "styled-components";
import heroImage from "homepage/images/heroImage.jpg";
import { grey300, blue400 } from "ui/colors";
import { portraitTablet, mobile } from "uiKit/theme/mediaQueries";

export const LayoutRoot = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;
export const TopSection = styled.div`
    position: relative;
    height: 100%;
    color: white;
    flex: 1 1 auto;

    &:before {
        background: url(${heroImage.src});
        position: absolute;
        content: "";
        display: block;
        width: 100%;
        height: 50vh;
    }
`;

export const Title = styled.div`
    text-align: center;
    margin: 20px auto;

    h2 {
        font-size: 1.5em;
    }
`;
export const TitleBox = styled(Title)`
    margin: 50px auto 20px;
    padding: 0 10px;

    position: relative;
    z-index: 1;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.8);

    ${mobile`
        margin: 10px auto;
    `};
`;
export const ContentContainer = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    position: relative;

    display: flex;
    justify-content: center;

    > :first-child {
        flex: 1 1 70%;
    }
`;

export const ContentBox = styled.div`
    flex: 1;
    max-width: 400px;
    padding: 20px;
    position: relative;
    color: black;

    margin: 0 10px 100px;

    border: 1px solid ${grey300};
    border-radius: 5px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    background: white;

    p {
        a:link {
            color: ${blue400};
            display: inline-block;
            > span {
                display: inline-block;
                border-bottom: 1px solid transparent;
            }

            &:hover > span {
                border-bottom-color: inherit;
            }
        }
    }

    form {
        width: 100%;
    }
`;

export const SecondaryContent = styled.div`
    flex: 1 1 30%;

    > * {
        margin-bottom: 20px;
    }

    ${portraitTablet`display:none;`};
`;
